.App {
  text-align: center;
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
  animation: easeInBounce 3s ease-in-out;
}

.App-logo:hover {
  transform: scale(1.1);
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #d9d9d9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #222222;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.primary-text {
  font-family: "poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

h1 {
  font-family: "poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 0px;
}

.round-dot {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #fc6803;
}

.button {
  background-color: #4D49A6;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  font-size: .6em; 
  font-style: normal;
  transition: all 0.3s ease;
  text-decoration: none;
}

.button:hover {
  background-color: #74a649;
  color: #000;
  transition: all 0.3s ease;
}

input {
  padding: 15px 15px;
  border: none;
  border-radius: 5px;
  font-style: bold;
  font-size: .6em;
  transition: all 0.3s ease;
  width: 200px;
  margin-right: 10px;
  text-decoration: none;
}

/* Responsive queries for medium and small screens */
@media (max-width: 768px) {
  .App-logo {
    height: 35vmin;
  }
}

@media (max-width: 480px) {
  .App-logo {
    height: 35vmin;
  }
}